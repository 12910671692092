<template>
  <v-menu
    bottom
    :close-on-content-click="closeOnContentClick"
    width="300"
    offset-y
    z-index="1"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        :placeholder="$_strings.order.FILTER"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section class="ml-4">
        <span class="caption font-weight-bold">Volume (m3)</span>
        <v-range-slider
          v-model="volume"
          :min="0"
          :max="10000"
          hide-details
          class="align-center mr-4"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="volume[0]"
              class="caption mt-0 pt-0"
              hide-details
              single-line
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              style="width: 4em"
              @change="$set(volume, 0, $event)"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="volume[1]"
              class="caption mt-0 pt-0"
              hide-details
              single-line
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              style="width: 4em"
              @change="$set(volume, 1, $event)"
            ></v-text-field>
          </template>
        </v-range-slider>
        <span class="caption font-weight-bold">Bobot (ton)</span>
        <common-auto-complete-items
          type="listTransportTypes"
          searchName="name"
          item-value="maxWeight"
          item-text="maxWeight"
          placeholder="Pilih Bobot"
          class="caption input-number mb-4 mr-4"
          flat
          v-model="filters.maxWeight"
          hide-details
          clearable
          @updateItems="updateTransportTypesItems"
          :filter="transportTypesFilterItems"
          :items="listMaxWeightItems"
        />
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          Terapkan Filter
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  created() {
    const { maxWeight } = this.$route.query;
    if (maxWeight) {
      this.listMaxWeightItems = [{
        maxWeight,
      }];
    }
  },
  data() {
    return {
      closeOnContentClick: false,
      volume: [0, 10000],
      listMaxWeightItems: [],
      transportTypesFilterItems: {
        search: '',
        page: 0,
        size: 50,
        totalData: 0,
      },
      filters: {
        volume: this.$route.query.volume || '',
        maxWeight: this.$route.query.maxWeight || '',
      },
    };
  },
  methods: {
    updateTransportTypesItems(items) {
      this.listMaxWeightItems = [...this.listMaxWeightItems, ...items];
    },
    setFilter() {
      this.closeOnContentClick = true;
      this.filters.volume = this.volume.toString();
      this.$emit('setNewFilters', this.filters);
    },
  },
};
</script>
