<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
    >
      <v-col cols="12" md="9">
        <v-row dense>
          <v-col cols="auto">
            <v-btn
              color="primary"
              small
              to="/main/tipe-kendaraan/create"
              v-if="userAccess.canCreate"
            >
              {{$_strings.transportTypes.CREATE_TRANSPORTTYPES_BUTTON_LABEL}}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <MasterDataCsvUpload
              :importTypeProp="$_strings.importType.TRANSPORT_TYPE"
              :uploadCsvProp="uploadCsv"
              :downloadTemplateCsvProp="downloadTemplateCsv"
              dataTypeProp="transport_type"
              @fetchList="fetching"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3">
        <v-row dense justify="end">
          <v-col cols="auto">
            <FilterList
              @setNewFilters="setNewFilters"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              dense
              hide-details
              v-model="searchText"
              outlined
              :placeholder="$_strings.transportTypes.SEARCH_TRANSPORTTYPES_INPUT_PLACEHOLDER"
              class="caption"
              ref="search"
              @keyup.native.enter="search"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="search">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <List
          :key="key_list"
          :filters="filters"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import List from './List.vue';
import FilterList from './FilterList.vue';

const MasterDataCsvUpload = () => import(/* webpackChunkName: "MasterDataCsvUpload" */ '../Common/MasterDataCsvUpload');
export default {
  name: 'TransportTypesList',
  components: {
    FilterList,
    List,
    MasterDataCsvUpload,
  },
  data() {
    return {
      key_list: 0,
      searchText: this.$route.query.name || '',
      filters: {
        volume: this.$route.query.volume || '',
        maxWeight: this.$route.query.maxWeight || '',
        name: this.$route.query.name || '',
      },
    };
  },
  methods: {
    fetching() {
      this.key_list += 1;
    },
    rowClicked(e, { item }) {
      this.$router.push(`/main/tipe-kendaraan/edit/${item.id}`);
    },
    search() {
      this.filters.name = this.searchText;
      const { search } = this.$route.query;
      if (this.searchText !== search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            name: this.searchText,
            page: 1,
          },
        });
      }
      this.fetching();
    },
    uploadCsv(formData) {
      return this.$_services.transportTypes.uploadCsv(formData);
    },
    downloadTemplateCsv() {
      return this.$_services.transportTypes.fetchCsvTemplateData();
    },
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.fetching();
    },
  },
};
</script>
