<template>
  <v-data-table
    :loading="isLoading"
    :headers="displayedHeader"
    :items="items"
    item-key="id"
    :server-items-length="totalItems"
    @click:row="rowClicked"
    :options.sync="pagination"
    :footer-props="{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': $_item_per_page,
      disablePagination: isLoading,
      disableItemsPerPage: isLoading
    }"
  >
    <template v-slot:[`item.imageUrl`]="{item}">
      <div v-if="!item.imageUrl" class="d-flex justify-center">
        <v-icon
          x-large
        >
          mdi-truck-outline
        </v-icon>
      </div>
      <v-img
        v-else
        max-width="150"
        :src="item.imageUrl"
      ></v-img>
    </template>
    <template v-slot:[`item.dimension`]="{item}">
      {{ item.length + ' x ' + item.width + ' x ' + item.height + 'cm, max.' + item.maxWeight }}
    </template>
    <template v-slot:[`item.maxWeight`]="{item}">
      {{ item.maxWeight + ' ton' }}
    </template>
    <template v-slot:[`item.isActive`]={item}>
      <v-select
        class="mt-5 pa-0 caption"
        style="width: 8rem"
        outlined
        dense
        :items="statusItems"
        item-value="value"
        item-text="key"
        v-model="item.isActive"
        @click.stop.prevent
        @change="onChangeIsActive(item, $event)"
        :disabled="!userAccess.canUpdate"
        :loading="item.isLoading"
      >
      </v-select>
    </template>
    <template v-slot:[`footer.page-text`]="props">
      <span>
        {{$_strings.transportTypes.PAGE_NAME}}
        <span v-if="items.length">
          {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
        </span>
      </span>
    </template>
  </v-data-table>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'TransportTypesList',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.transportTypes.PHOTO_TABLE_HEADER_LABEL,
          value: 'imageUrl',
          sortable: false,
        },
        {
          text: this.$_strings.transportTypes.NAME_TABLE_HEADER_LABEL,
          value: 'name',
        },
        {
          text: this.$_strings.transportTypes.DIMENSION_TABLE_HEADER_LABEL,
          value: 'dimension',
          sortable: false,
        },
        {
          text: this.$_strings.transportTypes.VOLUME_TABLE_HEADER_LABEL,
          value: 'volume',
          width: '8vw',
        },
        {
          text: this.$_strings.transportTypes.WEIGHT_TABLE_HEADER_LABEL,
          value: 'maxWeight',
          width: '8vw',
        },
        {
          text: this.$_strings.vaccine.STATUS,
          value: 'isActive',
          class: 'white--text primary text-capitalize',
          width: '8vw',
        },
      ],
      statusItems: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
      pagination: this.defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchTransportTypes();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    handleSortBy,
    handlerPagination,
    defaultPagination,
    async rowClicked(e, { item }) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.transportTypes.fetchTransportTypesDetail(item.id);
        this.$router.push({
          name: 'transport-types-edit-page',
          params: {
            id: item.id,
            data: result.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
    toggleLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async fetchTransportTypes() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.handleSortBy({ defaultValue: 'createdAt,desc', sortBy, sortDesc }),
        ...this.filters,
      };

      if (filters.name) filters.name = `qlike(${filters.name})`;
      if (filters.volume) filters.volume = `qbetweenfloat(${filters.volume})`;
      try {
        this.toggleLoading(true);
        const res = await this.$_services.transportTypes.fetchTransportTypes(this.skipEmptyStringObject(filters));
        this.items = res.data.contents;
        this.totalItems = res.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
    async onChangeIsActive(item, event) {
      const form = {
        ...item,
        isActive: event,
      };
      try {
        this.$set(item, 'isLoading', true);
        await this.$_services.transportTypes.createEditTransportType(form, item.id);
        this.$dialog.notify.success(this.$_strings.transportTypes.SUCCESS_EDIT_STATUS_MESSAGE_TEXT);
      } catch {
        this.$nextTick(() => {
          item.isActive = !event;
        });
      } finally {
        this.$delete(item, 'isLoading');
      }
    },
  },
};
</script>
