var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
    showCurrentPage: true,
    showFirstLastPage: true,
    'items-per-page-options': _vm.$_item_per_page,
    disablePagination: _vm.isLoading,
    disableItemsPerPage: _vm.isLoading
  }},on:{"click:row":_vm.rowClicked,"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.imageUrl",fn:function(ref){
  var item = ref.item;
return [(!item.imageUrl)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-truck-outline ")])],1):_c('v-img',{attrs:{"max-width":"150","src":item.imageUrl}})]}},{key:"item.dimension",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.length + ' x ' + item.width + ' x ' + item.height + 'cm, max.' + item.maxWeight)+" ")]}},{key:"item.maxWeight",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxWeight + ' ton')+" ")]}},{key:"item.isActive",fn:function(ref){
  var item = ref.item;
return [_c('v-select',{staticClass:"mt-5 pa-0 caption",staticStyle:{"width":"8rem"},attrs:{"outlined":"","dense":"","items":_vm.statusItems,"item-value":"value","item-text":"key","disabled":!_vm.userAccess.canUpdate,"loading":item.isLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.onChangeIsActive(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.transportTypes.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }